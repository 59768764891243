import getPrintFromTokenId from './getPrintFromTokenId';
import getSeriesFromTokenId from './getSeriesFromTokenId';
import seriesById from './seriesById';

const target = 'https://sale.boogie-woogie.io';
const baseFolder = 'run';
const runFolder = 'one';

const artists = {
  '01': 'mondrian',
  '02': 'matisse',
  '03': 'miro',
  '04': 'van-gogh',
  '05': 'rothko',
};

const artistById = (tokenId) => artists[getSeriesFromTokenId(tokenId)];

const generateAssetLink = (tokenId) => {
  const series = seriesById(tokenId);
  const artist = artistById(tokenId);
  const print = parseInt(getPrintFromTokenId(tokenId), 10);

  const link = `${series}-${artist}-print-${print}-run-1-thumbnail.jpeg`;

  return `${target}/${baseFolder}/${runFolder}/${series}/${link}`;
};

export default generateAssetLink;
