import * as React from 'react';

import flexUtilities from './flexUtilities';

import './columns.css';

const TwoColumns = ({ justify, align, style = {}, wrap = false, className = '', children }) => (
  <div
    className={`TwoColumnRow ${className}`}
    style={{
      justifyContent: flexUtilities.justifyContent(justify),
      alignItems: flexUtilities.alignItems(align),
      flexWrap: wrap ? 'wrap' : 'nowrap',
      ...style,
    }}
  >
    {children}
  </div>
);

export default TwoColumns;
