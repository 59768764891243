import * as React from 'react';
import { Helmet } from 'react-helmet';

import colors from '../colors/colors';

import generateAssetLink from '../utils/generateAssetLink';
import getPrintNumberFromTokenId from '../utils/getPrintNumberFromTokenId';
import seriesById from '../utils/seriesById';

import Layout from '../components/layouts/core';
import FlexWrapper from '../components/display/FlexWrapper';
import TwoColumns from '../components/display/TwoColumns';
import ArrowRightCircle from '../components/icons/ArrowRightCircle';

import './print-detail.css';

const propertyNameStyle = {
  color: colors.blue,
  fontSize: '0.675rem',
  margin: '0 0 0.5rem',
  lineHeight: '0.7rem',
};

const propertyDataStyle = {
  fontSize: '0.875rem',
  color: colors.blue,
  margin: 0,
  lineHeight: '1.4rem',
  textAlign: 'center',
  fontWeight: 900,
};

const propertyBlockStyle = {
  backgroundColor: colors.white,
  padding: '1.5rem 0.5rem 1rem',
  margin: '0 0 1rem',
  border: `1px solid ${colors.offWhite}`,
};

const linkStyles = {
  display: 'block',
  width: '100%',
  margin: '0 0 1rem',
  padding: '1rem',
  border: `1px solid ${colors.red}`,
  backgroundColor: colors.red,
  borderRadius: '0.25rem',
  textDecorationColor: colors.yellowDesaturated,
};

const PrintDetail = ({ pageContext }) => {
  const { title: tokenId, description, name, attributes } = pageContext;

  const thumbnail = generateAssetLink(tokenId);

  return (
    <>
      <Helmet>
        <title>{`Boogie-Woogie | ${name}`}</title>
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:site' content='@BoogieWoogieNFT' />
        <meta name='twitter:creator' content='@ics_jim' />
        <meta property='og:url' content={`https://boogie-woogie.io/print/${tokenId}`} />
        <meta property='og:title' content={`Boogie-Woogie | ${name}`} />
        <meta property='og:type' content='website' />
        <meta property='og:description' content={description} />
        <meta property='og:image' content={thumbnail} />
        <meta property='og:image:width' content='600' />
        <meta property='og:image:height' content='600' />
      </Helmet>
      <Layout>
        <TwoColumns style={{ padding: 'calc(1rem + 5vw)' }} align='center' justify='around'>
          <div className='ImageWrapper'>
            <img src={thumbnail} width='600' height='600' alt={`Print View: Boogie-Woogie ${name}`} />
          </div>
          <div>
            <p
              style={{
                color: colors.grey,
                fontSize: '0.875rem',
                lineHeight: '0.875rem',
                fontWeight: 900,
                margin: '0.75rem 0 0.5rem',
              }}
            >
              Boogie-Woogie
            </p>
            <h1
              style={{
                margin: '0.5rem 0 1rem',
                fontSize: '1.75rem',
                color: colors.darkGrey,
              }}
            >
              {name}
            </h1>
            <a
              className='ExternalLink'
              href={`https://sale.boogie-woogie.io/${seriesById(tokenId)}/${getPrintNumberFromTokenId(tokenId)}`}
              style={{
                ...linkStyles,
              }}
            >
              <FlexWrapper align='center' justify='between'>
                <span style={{ margin: '0 0.75rem 0 0', color: colors.white, fontWeight: 900 }}>View the Auction</span>
                <ArrowRightCircle aspect='1.5rem' color={colors.white} />
              </FlexWrapper>
            </a>
            <FlexWrapper align='stretch' justify='between' wrap>
              {attributes
                .filter((data) => !data.trait_type.includes('LName'))
                .map(({ trait_type, value }) => (
                  <FlexWrapper
                    key={`property-list-item-${trait_type.toLowerCase().replace(' ', '')}-${value}}`}
                    align='center'
                    justify='start'
                    column
                    style={propertyBlockStyle}
                    className='PropertyItem'
                  >
                    <p style={propertyNameStyle}>{trait_type}</p>
                    <p style={propertyDataStyle}>{value}</p>
                  </FlexWrapper>
                ))}
            </FlexWrapper>
            {/* <p>{description}</p> */}
          </div>
        </TwoColumns>
      </Layout>
    </>
  );
};

export default PrintDetail;
