import * as React from 'react';

import colors from '../../colors/colors';
import SVGWrapper from '../display/SVGWrapper';

const ArrowRightCircle = ({ aspect = '2rem', color = colors.yellowDesaturated }) => (
  <SVGWrapper viewBox='0 0 24 24' aspect={aspect} stroke={color}>
    <circle cx='12' cy='12' r='10'></circle>
    <polyline points='12 16 16 12 12 8'></polyline>
    <line x1='8' y1='12' x2='16' y2='12'></line>
  </SVGWrapper>
);

export default ArrowRightCircle;
