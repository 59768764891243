import getSeriesFromTokenId from './getSeriesFromTokenId';

const series = {
  '01': 'new-york-city',
  '02': 'nu-bleu-ii',
  '03': 'mural-for-the-terrace-plaza-hotel',
  '04': 'sunflowers',
  '05': 'yellow-blue-orange',
};

const seriesById = (tokenId) => series[getSeriesFromTokenId(tokenId)];

export default seriesById;
